@import '_bootstrap-variables';
@import '/node_modules/bootstrap/scss/_functions.scss';
@import '/node_modules/bootstrap/scss/_variables.scss';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

/* Increase contrast of links to get 100% on Lighthouse Accessability Audit. Override this color if you want to change the link color, or use a Bootswatch theme */
a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
}

/* override hover color for dropdown-item forced by bootstrap to all a:not([href]):not([tabindex]) elements in _reboot.scss */
a:not([href]):not([tabindex]):hover.dropdown-item {
  color: $dropdown-link-hover-color;
}

/* override .dropdown-item.active background-color on hover */
.dropdown-item.active:hover {
  background-color: mix($dropdown-link-hover-bg, $dropdown-link-active-bg, 50%);
}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid green;
}

// .form-select.ng-invalid,
// .form-control.ng-invalid,
// p-calendar.ng-invalid {
//   border-left: 5px solid red;
//   // border-color: #dc3545;
//   // padding-right: calc(1.5em + 0.75rem);
//   // background-image: url('../images/validation-alert-icon.svg');
//   // background-repeat: no-repeat;
//   // background-position: right calc(0.375em + 0.1875rem) center;
//   // background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
// }

// .ng-invalid:not(form){
//   // :not(form) is added to prevent a red bar on the left side of the whole form.
//   border-left: 5px solid red;
// }

/* other generic styles */

.jh-card {
  padding: 0.8%;
  margin-top: 10px;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[authsortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .jhi-toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: 50%;
  }
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row-md.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row-md.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */
