/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Bootstrap variables
@import '_bootstrap-variables';
// Import Bootstrap source files from node_modules
@import '/node_modules/bootstrap/scss/bootstrap.scss';

/* jhipster-needle-scss-add-vendor JHipster will add new css style */

@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/saga-blue/theme.css'; // pick whatever theme
@import '~primeng/resources/primeng.min.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '../../../../../node_modules/font-awesome/css/font-awesome.css';
// @import '~@angular/material/prebuilt-themes/indigo-pink.css';
